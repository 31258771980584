<template>
  <el-select
    v-model="model[orgIdProp]"
    :placeholder="allLabel"
    :disabled="disabled"
    :filterable="filterable"
    :clearable="clearable"
    @change="handleSelectAction"
  >
    <el-option
      v-for="item in organizations"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "OrganizationSelect",
  props: {
    model: {
      type: Object,
      default: () => {
        return { orgId: 0 };
      },
    },
    orgIdProp: {
      type: String,
      default: "orgId",
    },
    isAddAll: {
      type: Boolean,
      default: true,
    },
    allLabel: {
      type: String,
      default: "全部",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      organizations: [],
    };
  },
  created() {
    let o = this.$store.state.user.organizations;
    let start = 0;
    if (this.isAddAll) {
      this.organizations.push({
        id: 0,
        name: this.allLabel,
        taxNo: "",
        auditStatus: "Initial",
      });
      start = 1;
    }

    this.organizations.splice(start, 0, ...o);
  },
  methods: {
    handleSelectAction() {
      this.$emit("handleSelectAction", this.model);
    },
  },
};
</script>
